<template>
  <div class="home">
    <div class="welcome-banner">
      <div class="container">
        <h1 data-aos="fade-up">BİRLEŞTİREN TEKNOLOJİ</h1>
        <p data-aos="fade-up" data-aos-delay="150">Sizleri tüm dünya ile birleştiren dijitalleşme yolunuzda
          yanınızdayız.</p>
        <span data-aos="fade-up" data-aos-delay="300">Uzman kadromuzun hazırlayacağı canlı, kullanıcı dostu, iz bırakan web siteleri ve uygulamaları için iletişime geçin.
          <br>
          #websitesi #mobilapp #uygulama #frontend #backend #devops</span>
      </div>
    </div>
    <div class="about-us">
      <div class="container">
        <h1 data-aos="fade-up" class="page-title mb-50">HAKKIMIZDA</h1>
        <div class="page-content" data-aos="fade-up" data-aos-delay="150">
          <p> Souldy Tech, dijital dönüşümlerinize hizmet eden çok yönlü bir yazılım ailesidir.</p>
          <p> Kendini işine adamış ekibimiz, çevrimiçi platformların nüanslarını ve sürekli gelişen doğasını ve bunların
            başarılı bir işle ilişkilerini takip ederek size çözümler sunar.
          </p>
          <p> B2B ve B2C müşterilerimize 15 yıllık tecrübemiz ile web siteleri, uygulamalar, web portalları, e-ticaret
            platformları, devops hizmeti ve yazılım-süreç danışmanlığının yanı sıra diğer web araçları için güvenilir
            yazılımlar sağlıyoruz.
          </p>
          <p> Adım adım sürecimiz, her müşterinin bireysel ihtiyaçlarının karşılanmasını sağlarken aynı zamanda en
            yüksek kod kalitesiyle yazılım çözümlerinde mutlak en son çözümleri sunar.
          </p>
        </div>
      </div>
    </div>
    <div class="our-values">
      <div class="container">
        <h1 class="page-title mb-50" data-aos="fade-up">DEĞERLERİMİZ</h1>
        <div class="columns">
          <div class="column is-4" data-aos="fade-up" data-aos-delay="150">
            <div class="columns is-mobile vertical-align">
              <div class="column is-4-mobile">
                <img src="../assets/icons/steps.svg" alt="">
              </div>
              <div class="column is-8-mobile">
                <h1>Yapı</h1>
                <p>Adım adım ilerleme</p>
              </div>
            </div>
            <span>Her projenin unsurlarını doğru sırada ele almak önemlidir. Güçlü bir temelin gerçekten üstün bir nihai ürün elde ettirdiğine inanıyoruz.</span>
          </div>
          <div class="column is-4" data-aos="fade-up" data-aos-delay="300">
            <div class="columns is-mobile vertical-align">
              <div class="column is-4-mobile">
                <img src="../assets/icons/trusted.svg" alt="">
              </div>
              <div class="column is-8-mobile">
                <h1>Güven</h1>
                <p> Şeffaflıkla ilerleme</p>
              </div>
            </div>
            <span>Uzman ekibimiz ve müşterilerimiz arasındaki iletişim ve geri bildirim, Souldy Tech’in başarısının ayırt edici özelliğidir.</span>
          </div>
          <div class="column is-4" data-aos="fade-up" data-aos-delay="450">
            <div class="columns is-mobile vertical-align">
              <div class="column is-4-mobile">
                <img src="../assets/icons/target.svg" alt="">
              </div>
              <div class="column is-8-mobile">
                <h1>Detaycılık</h1>
                <p>Mükemmele doğru ilerleme</p>
              </div>
            </div>
            <span>"Yeterince iyi" bizim için yeterince iyi değil. Müşterilerimiz için daima en iyisini istiyoruz.</span>
          </div>
        </div>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "@/components/Contact";

export default {
  name: 'Home',
  components: {Contact},
}
</script>

<style lang="scss" src="./Home.scss"></style>
