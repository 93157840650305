import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Technology from '../views/Technology.vue'
import Education from '../views/Education.vue'
import Contact from '../components/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/teknoloji',
    name: 'technology',
    component: Technology
  },
  {
    path: '/egitim',
    name: 'education',
    component: Education
  },
  {
    path: '/iletisim',
    name: 'iletisim',
    component: Contact
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
