import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style/app.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './plugins/element'
import VueParticlesBg from "particles-bg-vue"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueParticlesBg, VueSweetalert2)

Vue.config.productionTip = false
const app = new Vue({
    created() {
        AOS.init()
    },
    router,
    render: h => h(App),
})
app.$mount('#app')
