import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Input,
  Loading,
  FormItem,
  Form,
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Loading)
Vue.use(FormItem)
Vue.use(Form)

Vue.prototype.$loading = Loading.service
