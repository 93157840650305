<template>
  <div class="technology-page">
    <div class="technologies">
      <div class="container">
        <h1 class="page-title mb-50" data-aos="fade-up">TEKNOLOJİ</h1>
        <div class="page-content">
          <p data-aos="fade-up" data-aos-delay="200">
            Eksiksiz bir yazılım, Bilgi Teknolojisi ürünleri ve hizmetleri
            yelpazesi sunmaktan gurur duyuyoruz. İster ilk web sitenizi
            oluşturuyor olun, ister var olan dijital platformlarınızı
            genişletmekle ilgileniyor olun, Souldy Tech; tasarım, işlevsellik ve
            yanıt verme konusunda en iyisini sağlayabilir. Günümüzde marka
            bilinci oluşturma sürekli değişen bir ortamdır ve özel yazılım
            çözümlerimiz, çevrimiçi ziyaretçiler için benzersiz bir deneyim
            oluşturmanıza yardımcı olabilir.
          </p>
          <p data-aos="fade-up" data-aos-delay="250">
            Hizmetlerimiz sadece geleneksel web siteleriyle sınırlı değildir;
            bir iOS veya Android uygulamasıyla mobil kullanıcılara ulaşın,
            ürünlerinizi sezgisel bir e-ticaret platformuyla satın veya açılış
            sayfanızı etkileşimli bir mini oyunla oyunlaştırın. Ekibimiz,
            vizyonunuz için doğru stratejiyi ve doğru kodu geliştirmek için
            sizinle birlikte çalışacaktır.
          </p>
          <p class="mt-30 mb-5" data-aos="fade-up" data-aos-delay="300">
            Aşağıdaki alanlarda kapsamlı hizmetler sunuyoruz:
          </p>
          <div class="columns is-multiline list">
            <div class="column is-3" data-aos="fade-up" data-aos-delay="350">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/web.svg" alt="" />
                </div>
                <div class="column is-7">Websiteleri ve açılış sayfaları</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="400">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/user-interface.svg" alt="" />
                </div>
                <div class="column is-7">Mobil Uygulamalar</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="450">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/software.svg" alt="" />
                </div>
                <div class="column is-7">Web Uygulamaları</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="500">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/responsive-design.svg" alt="" />
                </div>
                <div class="column is-7">Online platformlar ve e-portallar</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="550">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/shopping.svg" alt="" />
                </div>
                <div class="column is-7">E-Ticaret Siteleri</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="600">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/servers.svg" alt="" />
                </div>
                <div class="column is-7">Devops ve Sunucu Hizmetlerimiz</div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="650">
              <div class="columns is-mobile">
                <div class="column is-5">
                  <img src="../assets/icons/settings.svg" alt="" />
                </div>
                <div class="column is-7">Yazılım ve Süreç Danışmanlığı</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scope-work">
      <div class="container">
        <h1 class="page-title mb-50" data-aos="fade-up">İŞ KAPSAMIMIZ</h1>
        <div class="page-content">
          <p data-aos="fade-up" data-aos-delay="200">
            Müşterilerimizle şeffaf, yapılandırılmış bir çalışma ilişkisinin
            mümkün olan en iyi sonuçları verdiğine inanıyoruz.
          </p>
          <p data-aos="fade-up" data-aos-delay="250">
            Souldy Tech ekibi olarak, kullanıcı deneyimini ve arayüz tasarımını
            hesaba katmak için teknolojik uzmanlığımızı kullanırken, dijital bir
            platform veya web sitesi için fikrinizi planlamak ve düzenlemek için
            sizinle yakın bir şekilde çalışıyoruz. Bir beyin fırtınası
            oturumundan başlayarak, dijital projelerini genişletmek ve analiz
            etmek için müşterilerimizin her biriyle işbirliği yapıyoruz. Doğru
            yazılımı ve kodu belirledikten sonra tasarlamaya başlarız. Geri
            bildirim bu aşamada önemlidir ve müşterilerimizi tasarım sürecinin
            her yönüne dahil ederiz. Platformunuz test edildikten, optimize
            edildikten ve piyasaya sürüldükten sonra da destek sunuyoruz.
          </p>
          <p data-aos="fade-up" data-aos-delay="300">
            Sürecimiz şunları içerir:
          </p>
          <ul>
            <li data-aos="fade-up" data-aos-delay="350">
              <p><img src="../assets/icons/conversation.svg" alt="" /></p>
              <p>kick-off toplantısı</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <p><img src="../assets/icons/analytics.svg" alt="" /></p>
              <p>analiz</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="450">
              <p><img src="../assets/icons/technology.svg" alt="" /></p>
              <p>teknoloji değerlendirme</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              <p><img src="../assets/icons/design.svg" alt="" /></p>
              <p>UX design</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="550">
              <p><img src="../assets/icons/ui-design.svg" alt="" /></p>
              <p>UI design</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="600">
              <p><img src="../assets/icons/programming.svg" alt="" /></p>
              <p>Frontend</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="650">
              <p><img src="../assets/icons/cms.svg" alt="" /></p>
              <p>CMS</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="700">
              <p><img src="../assets/icons/database.svg" alt="" /></p>
              <p>Database</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="750">
              <p><img src="../assets/icons/page-speed.svg" alt="" /></p>
              <p>Optimizasyon</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="800">
              <p><img src="../assets/icons/unit-testing.svg" alt="" /></p>
              <p>Bireysel Testler</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="850">
              <p><img src="../assets/icons/ab-testing.svg" alt="" /></p>
              <p>Otomatik Testler</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="900">
              <p><img src="../assets/icons/launch.svg" alt="" /></p>
              <p>Canlıya geçiş</p>
            </li>
            <li data-aos="fade-up" data-aos-delay="950">
              <p><img src="../assets/icons/support.svg" alt="" /></p>
              <p>Destek hizmeti</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "@/components/Contact";

export default {
  name: "Technology",
  components: { Contact },
};
</script>

<style lang="scss" src="./Technology.scss"></style>
