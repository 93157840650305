<template>
  <div class="contact">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="page-title mb-50" data-aos="fade-up" data-aos-delay="850">
            BİZE ULAŞIN
          </h1>
        </div>
      </div>
      <div class="columns" data-aos="fade-up" data-aos-delay="850">
        <div class="column is-6">
          <p>
            Deneyimli ve alanlarında uzman bir ekiple mi görüşmek istiyorsunuz?
          </p>
          <p>Hadi tanışalım!</p>
          <form ref="contactForm">
            <el-input
              v-model="form.fullName"
              type="text"
              name="from_name"
              placeholder="Adınız ve Soyadınız*"
            ></el-input>
            <el-input
              v-model="form.email"
              type="email"
              name="user_email"
              placeholder="Mail Adresiniz*"
            ></el-input>
            <el-input
              v-model="form.message"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              name="message"
              placeholder="Mesajınız*"
            ></el-input>
            <div class="columns">
              <div class="column">
                <div class="buttons">
                  <button
                    @click.prevent="sendEmail"
                    :disabled="
                      (form.email != '') &
                      (form.message != '') &
                      (form.fullName != '') &
                      (recaptchaValue != '')
                        ? false
                        : true
                    "
                    class="button is-primary"
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="column is-5 is-offset-1">
          <div class="columns">
            <div class="column has-text-centered">
              <img src="../assets/images/souldy-tech-logo.png" alt="" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <strong>Adres:</strong>
            </div>
            <div class="column">
              Levent, Talatpaşa Cd Şair Çelebi sok No1 Kat 2 , Ortabayır Mah,
              34413 Kağıthane/İstanbul
            </div>
          </div>
          <div class="columns">
            <div class="column is-2">
              <strong>Email:</strong>
            </div>
            <div class="column">info@souldytech.com</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
export default {
  name: "Contact",
  data() {
    return {
      form: {
        fullName: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .send(
          "service_4sbp98s",
          "template_ryvoo2f",
          {
            from_name: this.form.fullName,
            user_email: this.form.email,
            message: this.form.message,
          },
          "WkLEHVmeeB-p7yLyS"
        )
        .then(
          () => {
            Swal.fire("Tebrikler!", "Mesajınız Gönderildi.", "success");
          },
          () => {
            Swal.fire(
              "Hata!",
              "Mesajınız Gönderilemedi. Lütfen info@souldy.com ile irtibata geçiniz.",
              "error"
            );
          }
        );
    },
  },
};
</script>

<style lang="scss" src="./Contact.scss"></style>

