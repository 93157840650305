<template>
  <div class="education-page">
    <div class="educations">
      <div class="container">
        <div data-aos="fade-up">
          <img src="../assets/images/webece.jpg" alt="Ece Hazal Genc Bati" />
        </div>
        <div class="columns" data-aos="fade-up" data-aos-delay="150">
          <div class="column is-10">
            <h1 class="page-title mb-50">EĞİTİMLER</h1>
          </div>
          <div class="column is-2">
            <a
              href="/assets/pdf/SouldyTech-saglikli-bireyler.pdf"
              target="_blank"
            >
              <div class="buttons" style="float: right">
                <button class="button is-success">Detaylı Bilgi İçin</button>
              </div>
            </a>
          </div>
        </div>

        <div class="page-content">
          <p data-aos="fade-up" data-aos-delay="200">
            <span>Sağlıklı Bireylerden Mutlu Bireylere…</span> <br /><br />
            <span>Peki biz neler yapıyoruz?</span> <br /><br />
            Souldy Tech olarak “Mindfullness (Farkındalıklı) Yaşam”, "Nefesle
            Yaşam Yönetimi”, “Mutluluk Yönetimi”, “Stres Yönetimi”, “Farkındalık
            ve Meditasyon”, “Beden Farkındalığı; Yin Çalışmalar I Omurgadaki
            Hayat I Yüz Yogası”, “Bütünsel Sağlık” konularında kurum ihtiyacına
            yönelik olarak 1 saaten tüm yılı kapsayan paketlere kadar bir çok
            ihtiyacı karşılıyoruz.<br /><br />
            Çalışmalarımızın sürekliliği adına firmamıza ait olan “Souldy App”
            meditasyon ve sağlık uygulamamızı kurum çalışanlarına 3 ay boyunca
            ücretsiz temin ediyoruz Aylık çalışmalarımızda, birlikte ihtiyacı
            belirliyor ve haftalık düzenli tekrarlarla alışkanlık oluşturulması
            için destek sunuyoruz.<br /><br />
            Yüz yüz veya online seçenekli çalışmalarımıza online katılım
            durumunda programa katılan çalışanların, programı tamamlama
            durumları kurumlara KVKK kapsamında raporlanmaktadır.
          </p>
          <div class="columns is-multiline icon-list">
            <div class="column" data-aos="fade-up" data-aos-delay="250">
              <img src="../assets/images/souldy-icon.jpg" alt="" />
            </div>
            <div class="column" data-aos="fade-up" data-aos-delay="300">
              <img src="../assets/images/souldy-icon-1.jpg" alt="" />
            </div>
            <div class="column" data-aos="fade-up" data-aos-delay="350">
              <img src="../assets/images/souldy-icon-2.jpg" alt="" />
            </div>
            <div class="column" data-aos="fade-up" data-aos-delay="400">
              <img src="../assets/images/souldy-icon-3.jpg" alt="" />
            </div>
            <div class="column" data-aos="fade-up" data-aos-delay="450">
              <img src="../assets/images/souldy-icon-4.jpg" alt="" />
            </div>
            <div class="column" data-aos="fade-up" data-aos-delay="500">
              <img src="../assets/images/souldy-icon-5.jpg" alt="" />
            </div>
          </div>
          <div class="columns" data-aos="fade-up" data-aos-delay="550">
            <div class="column is-9">
              <p>
                <span>Eğitimleri kim veriyor?</span> <br /><br />
                ECE HAZAL GENC BATI, Souldy Tech firmasının kurucu ortağı, 17
                yıl Türkiye’nin önde gelen kurumsal firmalarında iş analistliği
                ve yöneticiliği yapmış lider koç, onlarca kurum ve üniversiteye
                Wellbeing alanlarında eğitimler vermiş nefes ve yaşam koçu,
                bireysel ve kurumsal olarak yüzlerce kişi ile çalışan yin yoga
                ve yüz yogası eğitmenidir.<br /><br />App Store ve Google
                Play’de binlerce kullanıcısı olan “Souldy App” uygulamasının
                içerik üreticisi ve sesidir. Endüstri Mühendisi olup tüm çalışma
                hayatına ve öğretilere analitik bakış açısı ile yaklaştığını
                ifade eden Ece, çoğunluk için daha huzurlu ve verimli bir hayat
                hedeflemektedir.<br /><br />Yaklaşık olarak 10 yıldır meditasyon
                ve wellbeing alanlarında onlarca eğitmenden eğitim alan ve
                almaya devam eden Ece, herkes gibi. Nasıl yönetebileceğini
                biliyor olması dışında kaygıları, stresi, korkuları, mutluluğu
                ve heyecanı bol olan bir hayat yaşıyor.
              </p>
            </div>
            <div class="column image-col">
              <img
                src="../assets/images/webece2.jpg"
                alt="Ece Hazal Genc Bati"
              />
            </div>
          </div>

          <p class="mt-30 mb-5" data-aos="fade-up" data-aos-delay="600">
            Referanslar:
          </p>
          <div class="columns is-multiline list">
            <div class="column is-3" data-aos="fade-up" data-aos-delay="650">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/ikea-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="700">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/sinpas-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="750">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/tema-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="800">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/gaziantep-uni-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="850">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/travelbuild-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="900">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/gedik-uni-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="950">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/sakarya-uni-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="1000">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/delta-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="1050">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img
                    src="../assets/images/eskisehir-osmangazi-uni-logo.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="1100">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/yalova-uni-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="1150">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/shopi-logo.jpg" alt="" />
                </div>
              </div>
            </div>
            <div class="column is-3" data-aos="fade-up" data-aos-delay="1200">
              <div class="columns is-mobile">
                <div class="column is-12">
                  <img src="../assets/images/cafetur-logo.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact></contact>
  </div>
</template>

<script>
import Contact from "@/components/Contact";

export default {
  name: "Education",
  components: { Contact },
};
</script>

<style lang="scss" src="./Education.scss"></style>
