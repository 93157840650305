<template>
  <div id="app">
    <particles-bg color="#42b983" type="cobweb" :num="freq" :bg="true" />
    <Menu/>
    <transition name="fade">
      <router-view/>
    </transition>
    <div class="mouse_wave">
      <span class="scroll_arrows one"></span>
      <span class="scroll_arrows two"></span>
      <span class="scroll_arrows three"></span>
    </div>
  </div>
</template>
<script>
import Menu from "@/components/Menu";

export default {
  name: 'SouldyTech',
  components: { Menu },
  data () {
    return {
      freq: 100,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 1024) {
        this.freq = 40
      }
    }
  },
}
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
