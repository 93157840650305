<template>
  <div class="menu" data-aos="fade-down">
    <div class="container">
      <div class="nav-brand">
        <router-link to="/">
          <img src="../assets/images/souldy-tech-logo.png" alt="" />
        </router-link>
      </div>
      <span v-if="isMobile" class="menu-btn" @click="showMenu = !showMenu">
        <svg
          class="ham hamRotate ham8"
          viewBox="0 0 100 100"
          width="50"
          onclick="this.classList.toggle('active')"
        >
          <path
            class="line top"
            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
          />
          <path class="line middle" d="m 30,50 h 40" />
          <path
            class="line bottom"
            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
          />
        </svg>
      </span>
      <div class="menu-list" :class="showMenu ? 'show' : 'closed'">
        <ul>
          <li>
            <a @click="goToPath('teknoloji')" to="/teknoloji">TEKNOLOJİ</a>
          </li>
          <li>
            <a @click="goToPath('egitim')" to="/egitim">EĞİTİMLER</a>
          </li>
          <li>
            <a @click="goToPath('iletisim')" to="/iletisim">İLETİŞİM</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      isMobile: false,
      showMenu: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      }
    },
    goToPath(routerName) {
      window.scrollTo(0, 0);
      this.showMenu = false;
      this.$router.push({ path: "/" + routerName });
    },
  },
};
</script>

<style lang="scss" src="./Menu.scss"></style>

